.bbtop {
    top: 0;
}

#main {
    color: #161317;
    box-sizing: border-box;
    overflow-x: clip;
}

#homepage .first-content .splide {
    position: inherit;
}

.nav-link:focus-visible {
    box-shadow: unset;
}

#background_home_page {
    background-image: url(https://cdn.yopaz.com/assets/images/home/main_bg.avif);
    background-repeat: repeat;
    position: absolute;
    z-index: -100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

#homepage .btn-to-news {
    display: flex;
    justify-content: center;
    text-decoration: none;
    margin-inline: auto;
    padding: 20px 30px;
    border: 1px solid #BDBDBD;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0em;
    cursor: pointer;
    color: black;
    text-align: center;
    max-width: 190px;
    margin-top: 20px;
}

#homepage .btn-to-news:hover {
    background: #e74c39;
    color: white;
    border: 1px solid #e74c39;
    transition: all 0.2s ease-in;
    box-shadow: 0px 4px 18px 0px #ff2121ab;
}

#homepage .btn-to-news a {
    color: black !important;
    text-decoration: none;
}

#homepage .btn-to-news:hover a {
    color: white !important;
}

#homepage {
    position: relative;
}

#homepage .video-layout {
    width: 100%;
}

#homepage .banner {
    position: relative;
}

#homepage .banner-content {
    position: absolute;
    top: 39%;
    left: 13%;
}

#homepage .banner-content {
    color: #fff;
    font-size: 36px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
}

#homepage .social {
    display: flex;
    align-items: end;
}

#homepage .btn-custom {
    width: 337px;
    height: 72px;
    border: 2px solid #FFFFFF;
    color: #ffffff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset;
    transition: background 0.6s, box-shadow 0.6s;
    font-size: 24px;
    font-weight: 700;
    margin-top: 40px;
}

#homepage .btn-custom:hover {
    box-shadow: 0px 4px 18px 0px #FF2121AB;
    background: #E74C39;
    border: 0;
}

#homepage .body-content {
    text-align: center;
    margin-top: 88px;
    margin-bottom: 40px;
    font-size: 36px;
    font-weight: 700;
}

#homepage .first-layout {
    border-top: 2px solid #D1D1D1
}

#homepage .box {
    padding: 40px 44px 40px 44px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#homepage .introduce-break-line {
    border-top: 2px solid #E0E0E0;
}

#homepage .title-box {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 12px;
}

#homepage .content-box {
    line-height: 24px;
    font-weight: 400;
    font-size: 14px;
    text-align: justify;
    min-height: 120px;
}

#homepage .background-industry {
    position: absolute;
    z-index: -10;
    bottom: 0;
    width: 100%;
}

#homepage .background-content {
    margin-top: 262px;
    position: relative;
}

#homepage .second-content {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
}

#homepage .third-layout {
    max-width: 1325px;
    margin-inline: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin-top: 44px;
    padding-bottom: 138px;
    justify-content: center;
}

#homepage .tech-layout-mb {
    display: none;
}

#homepage .second-content p {
    margin-bottom: 0;
}

#homepage .four-layout {
    width: 85%;
    margin: 72px auto;
    display: flex;
    justify-content: space-between;
}

#homepage .title-layout {
    font-weight: 700;
    font-size: 36px;
}

#homepage .customer-content {
    padding: 118px 297px;
}

#homepage .icon-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
}

#homepage .icon-box p {
    text-align: center;
    margin-top: 24px;
}

#homepage .icon-box-2 {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 40px;
}

#homepage .icon-box-2 p {
    text-align: center;
    margin-top: 24px;
}

#homepage .customer {
    position: relative;
}

#homepage .title-slide {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
}

#homepage .first-slide {
    margin-top: 118px;
    padding-bottom: 40px;
}

#homepage .image-slide img {
    object-fit: cover;
    width: 314px;
    height: 314px;
    border: 1px solid #eae5e5;
}

#homepage .first-content {
    margin: 0 297px 0 297px;
}

#homepage .background-banner:hover .content-tech img {
    opacity: 0;
    margin-top: -60px;
    margin-bottom: 40px;
    transition: all .4s ease-out;
}

#homepage .image-slide {
    margin: 30px 40px 0px 0;
}

#homepage .content-slide {
    display: flex;
    justify-content: center;
}

#homepage .name {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 5px;
}

#homepage .job {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

#homepage .description-slide {
    line-height: 24px;
    font-weight: 300;
    font-size: 18px;
    color: #4E4D52;
    max-width: 630px;
    text-align: justify;
}

#homepage .info {
    padding: 30px 58px;
    border: 8px solid #E4E4E4;
    margin-top: 30px;
    margin-bottom: 50px;
}

#homepage #customer-splide::after {
    content: '';
    background: #ffffff;
    width: 1326px;
    height: 469px;
    position: absolute;
    top: 110px;
    right: calc((100% - 1326px) / 2);
}

#homepage .background-banner {
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 340px;
    background-position: center;
    background: inherit;
    position: relative;
}

#homepage .background-banner:hover .img-bg {
    position: absolute;
    z-index: -1;
    filter: blur(4px);
    transition: all .5s ease-out;
}

#homepage .background-banner .img-bg {
    position: absolute;
    z-index: -1;
    transition: all .5s ease-out;
}

#homepage .content-tech {
    padding: 64px 44px;
    text-align: center;
    color: #ffffff;
    transition: all .5s ease-out;
}

#homepage .background-banner:hover .content-tech {
    z-index: 1;
    padding: 60px 44px 0px 44px;
    transition: all .5s ease-out;
}

#homepage .break-line {
    margin-top: 12px;
    margin-bottom: 12px;
    color: #FFFFFF;
    opacity: 1;
}

#homepage .break-line-after {
    margin-top: 12px;
    margin-bottom: 12px;
    color: #FFFFFF;
    position: relative;
    transition: all .5s ease-out;
    top: 30px;
    opacity: 0;
    display: block;
}

#homepage .background-banner:hover .break-line-after {
    opacity: 1;
    display: block;
    top: 0;
    transition: all .5s ease-out;
}

#homepage .title-tech {
    margin-bottom: 0;
    margin-top: 24px;
    font-size: 20px;
    font-weight: 600;
    transition: all .5s ease-out;
}

#homepage .background-banner:hover .title-tech {
    top: 0;
    transition: all .5s ease-out;
    margin-top: -40px;
}

#homepage .info-tech {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Kozuka-Gothic-Pr6N', sans-serif;
}

#homepage .info-tech-after {
    display: block;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 300;
    transition: all .5s ease-out;
    top: 70px;
    opacity: 0;
    font-family: 'Kozuka-Gothic-Pr6N', sans-serif;
}

#homepage .background-banner:hover .info-tech-after {
    display: block;
    transition: all .5s ease-out;
    top: 0px;
    opacity: 1;
}

#homepage .partner {
    text-align: center;
    margin: 199px 80px 118px 79px;
}

#homepage .splide__pagination__page.is-active {
    background: #E74C39;
}

#homepage .question {
    max-width: 1325px;
    margin-inline: auto;
}

#homepage .question-head {
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 40px;
    padding-top: 88px;
}

#homepage .question .accordion-button::after {
    font-weight: 900;
    width: 35px;
    height: 35px;
    margin-top: auto;
    background-size: 25px;
}

#homepage .accordion-flush .accordion-collapse {
    background-color: #FFFFFF;
    border-top: 1px solid #CCCCCC
}

#homepage .question .accordion-button:not(.collapsed)::after {
    margin-right: 9px;
    font-weight: 900;
    width: 35px;
    height: 35px;
    margin-top: auto;
    background-size: 25px;
}

#homepage .question-content {
    padding: 28px 24px;
    border-bottom: 1px solid #CCCCCC;
}

#homepage .text-right {
    text-align: right;
}

#homepage .question-content:first-child {
    border-top: 1px solid #CCCCCC;
}

#homepage .question-number {
    font-size: 20px;
    font-weight: 700;
    margin-right: 180px;
}

#homepage .text-question {
    font-weight: 500;
    font-size: 18px;
    font-family: 'Kozuka-Gothic-Pr6N', sans-serif;
}

#homepage .news {
    margin: 0 auto;
    max-width: 1325px;
    padding-bottom: 88px;
}

#homepage .news-title {
    margin-top: 88px;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 40px;
}

#homepage .news-slide {
    border: 1px solid var(--e-0-e-0-e-0, #D1D1D1);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px #E4E4E4;
    height: 100%;
    padding: 36px 36px 52px 36px;
}

#homepage .news-slide .convert-news {
    position: relative;
    overflow: hidden;
    margin-top: 22px;
    margin-bottom: 24px;
}

#homepage .news-slide a {
    cursor: pointer;
}

#homepage .news-slide:hover .img-news {
    scale: 1.05;
    transition: all 0.5s ease-in-out;
}

#homepage .tag-news:hover {
    background-color: #E74C39;
    color: #FFFFFF;
    border: 1.2px solid #E74C39;
    box-shadow: 0px 4px 18px 0px #FF2121AB;
    transition: all 0.2s ease-in-out;
}

#homepage .news-slide .row {
    --bs-gutter-x: unset;
}

#homepage .header-new {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

#homepage .tag-news {
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 139px; */
    padding: 4px 10px;
    border: 1.2px solid #161317;
    margin-right: 8px;
    margin-bottom: 16px;
}

#homepage .name-new {
    padding: 5px;
    background-color: #161317;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    margin-right: 18px;
}

#homepage .date-new {
    font-size: 14px;
    font-weight: 700;
    margin-right: 18px;
}

#homepage .view-new {
    font-size: 14px;
    font-weight: 700;
    margin-right: 18px;
}

#homepage .title-new {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
    min-height: 54px;
}

#homepage .title-new:hover {
    color: #E74C39;
}

#homepage .img-news {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

#homepage .content-new {
    height: 108px;
    overflow: hidden;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: #4E4D52;
}

#homepage .line-new {
    width: 125px;
    background-color: #CCCCCC;
    height: 2px;
    margin-top: 52px;
}

#homepage .splide__pagination {
    margin: 50px auto 0 auto;
    position: relative;
    z-index: 3;
}

#homepage .splide__pagination li {
    height: 16px;
    width: 16px;
    margin: 0 5px;
}

#homepage .splide__pagination li button {
    height: 16px;
    width: 16px;
}

#homepage .splide__pagination__page.is-active {
    opacity: 1;
    background: #E74C39;
    transform: scale(1.0);
    z-index: 1;
}

#homepage .splide__track {
    z-index: 2;
}

#homepage .accordion-custom {
    display: flex;
    align-items: center;
}

#homepage .accordion-button-custom {
    padding: 28px 24px;
}

#homepage .custom-background {
    background-color: unset;
}

#homepage .accordion-item {
    background-color: unset;
}

#homepage .accordion-item-custom {
    border-bottom: 1px solid #CCCCCC;
}

.accordion-button:not(.collapsed) .text-question {
    font-weight: 700 !important;
}

#homepage .accordion-item-custom:hover {
    border-bottom: 2px solid #E74C39;
}

#homepage .accordion-item-custom:first-child {
    border-top: 1px solid #CCCCCC !important;
}

.letter-mb {
    display: none;
    cursor: pointer;
}

#homepage .introduce-container {
    max-width: 1325px;
    margin: auto;
}

#homepage .introduce img {
    width: 100%;
}

#homepage .mg {
    padding-left: 0;
    padding-right: 0;
}

#homepage #partner-splide .splide__slide img {
    width: 100%;
}

.private-box a {
    color: #ffffff;
}


.social-mb {
    display: none;
}

.social img {
    width: 40px;
    height: 40px;
}

#homepage .knowledge-box {
    padding-block: 80px 92px;
}

/* KNOWLEDGE SECTION */
.knowledge {
    background-color: #fff;
}

.knowledge-container {
    max-width: 1325px;
    margin-inline: auto;
    margin: 0 auto;
}

.knowledge .item {
    text-align: center;
}

.knowledge .knowledge-img {
    display: inline-block;
    background: #f7f7f7;
    padding: 29px;
    border-radius: 28px;
}

.knowledge .title {
    text-align: center;
    padding-block: 12px;
}

.knowledge .content {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    gap: 30px 19px;
}

.knowledge .content .item {
    flex: 0 0 auto;
    width: 120px;
}

.knowledge .knowledge-title {
    text-align: center;
    margin-top: 12px;
    font-size: 16px;
    font-weight: 300;
}

.header .dropdown-menu[data-bs-popper] {
    left: -50%;
}

.img-box {
    display: none;
    width: 100%;
}

.navbar-toggler:focus:focus,
.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #161317;
}

.myBtn {
    width: 52px;
    height: 52px;
    border-radius: 100%;
    background: rgb(255, 255, 255, 0.5);
    position: fixed;
    z-index: 9999;
    bottom: 90px;
    border: 0;
    right: 28px;
    cursor: pointer;
}

.myBtn:hover {
    background: #ffffff;
    box-shadow: 0px 4px 18px 0px #FF2121AB;
    transition: all 0.5s ease-in-out;
}

.myBtn svg {
    margin-inline: auto;
}

#main .accordion-body {
    padding: 28px 24px;
}

@media (max-width: 991px) {
    .myBtn {
        right: 30px;
    }

    #homepage .btn-to-news {
        padding: 10px 20px;
        font-size: 14px;
    }
}

@media (max-width: 1820px) {
    #homepage .content-tech {
        padding: 64px 44px 0px 44px;
    }
}

@media (max-width: 1770px) {

    #homepage .content-tech {
        padding: 44px 44px 0px 44px;
    }

    #homepage .background-content {
        margin-top: 222px;
    }

    #homepage .knowledge-box {
        padding: 72px 140px;
    }
}

@media (max-width: 1600px) {
    .col-footer-custom {
        padding-left: 0;
        padding-right: 0;
    }

    #homepage .background-content {
        margin-top: 140px;
    }

    #homepage .box {
        padding: 20px;
    }

    #homepage .knowledge-box {
        padding: 72px 80px;
    }

    .knowledge .knowledge-img {
        padding: 24px;
    }

    #homepage .info-tech-after {
        font-size: 13px;
    }
}

@media (max-width: 1500px) {
    #homepage .content-tech {
        padding: 38px 38px 0 38px;
    }

    #homepage .first-content {
        margin: 0 60px;
    }

    #homepage #customer-splide::after {
        width: 1100px;
        right: calc((100% - 1100px) / 2);
    }

    #homepage .description-slide {
        width: 500px;
    }

    #homepage .knowledge-box {
        padding: 72px 60px;
    }

    #homepage .question-head {
        padding-top: 58px;
    }

    #homepage .partner {
        margin: 199px 40px 118px 40px;
    }
}

@media (max-width: 1400px) {

    #homepage .first-content {
        margin: 0 20px;
    }

    #homepage .knowledge-box {
        padding: 72px 20px;
    }
}

@media (max-width: 1366px) {
    #homepage .banner-content {
        font-size: 24px;
        top: 34%;
        left: 8%;
    }

    #homepage .btn-custom {
        font-size: 20px;
        margin-top: 20px;
    }

    #homepage .introduce-container, #homepage .question, #homepage .news{
        max-width: 1100px;
    }

    #homepage .body-content, #homepage .second-content, #homepage .title-layout, #homepage .title-slide, #homepage .question-head, #homepage .news-title {
        margin-top: 40px;
        font-size: 28px;
    }

    #homepage .title-box, #homepage .title-tech {
        font-size: 18px;
    }

    #homepage .third-layout {
        max-width: 1100px;
        gap: 20px;
    }

    #homepage .background-banner {
        height: 300px;
    }

    .knowledge-container {
        max-width: 1100px;
    }

    .knowledge .content {
        gap: 30px 0px;
    }

    #homepage .news-slide {
        padding: 20px;
    }

    #homepage .title-new {
        font-size: 16px;
    }

    #homepage .accordion-button-custom, #main .accordion-body, #homepage .text-question {
        padding: 16px;
    }

    #homepage .partner {
        margin: 80px 40px 40px;
    }
}

@media (max-width: 991px) {
    .slogan {
        height: 516px;
    }

    #homepage #customer-splide::after {
        height: 300px;
        top: 80px;
        width: 750px;
        right: calc((100% - 750px) / 2);
    }

    #homepage .image-slide {
        margin: 30px 40px 0px 100px;
    }

    #homepage .image-slide img {
        width: 220px;
        height: 220px;
    }

    #homepage .partner {
        margin: 100px 20px 28px 20px;
    }

    #homepage .splide__pagination {
        margin: 20px auto 0 auto;
    }

    #homepage .info {
        margin-right: 120px;
        margin-bottom: 20px;
    }

    #homepage .description-slide {
        margin-right: 100px;
    }

    #homepage .image-slide {
        margin-right: 20px;
    }

    #homepage .info {
        padding: 14px 20px;
    }

    #homepage .background-banner .img-bg,
    #homepage .background-banner {
        max-width: 370px;
    }

    #homepage .third-layout {
        grid-template-columns: repeat(2, 371px);
    }

    .header .nav-item .show:first-child {
        border-top: none;
        padding-bottom: 0 !important;
    }

    .nav-contact {
        height: 52px;
        padding: 15px 17px;
        margin-left: 0px;
        width: 52px;
    }

    #homepage .banner-content {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        justify-content: center;
    }

    #homepage .btn-custom {
        margin: 30px 57.5px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0em;
        width: 178px;
        height: 44px;
    }

    #homepage .second-content,
    #homepage .body-content,
    #homepage .title-layout,
    #homepage .title-slide,
    #homepage .question-head,
    #homepage .news-title {
        font-size: 18px;
        font-weight: 700;
    }

    #homepage .body-content {
        margin-top: 36px;
        margin-bottom: 16px;
    }

    #homepage .title-box {
        font-size: 16px;
        font-weight: 700;
    }

    #homepage .content-box {
        font-weight: 400;
    }

    #homepage .box {
        padding: 20px 24px;
    }

    #homepage .background-content {
        margin-top: 29px;
    }

    #homepage .third-layout,
    .tech-layout-mb {
        margin: 16px 5px 0px 5px
    }

    #homepage .title-tech {
        font-size: 14px;
        font-weight: 700;
    }

    #homepage .info-tech,
    #homepage .info-tech-after {
        font-size: 13px;
        font-weight: 400;
    }

    .knowledge-container {
        padding-top: 24px;
    }

    #homepage .title-layout {
        margin-bottom: 16px;
    }

    .knowledge .knowledge-title {
        font-size: 12px;
        font-weight: 500;
    }

    #homepage .first-slide {
        padding-bottom: 16px;
        margin-top: 24px;
    }

    #homepage .name {
        font-size: 16px;
        font-weight: 700;
    }

    #homepage .job {
        font-size: 14px;
        font-weight: 500;
    }

    #homepage .question-head {
        padding-top: 36px;
        margin-bottom: 16px;
    }

    #homepage .question-number {
        font-size: 14px;
        font-weight: 700;
    }

    #homepage .custom-background,
    #homepage .text-question {
        font-size: 14px;
        font-weight: 500;
    }

    #homepage .news-title {
        margin-top: 36px;
        margin-bottom: 20px;
    }

    #homepage .view-new,
    #homepage .date-new {
        font-size: 12px;
        font-weight: 700;
    }

    #homepage .title-new {
        font-size: 15px;
        font-weight: 700;
        min-height: 40px;
    }

    #homepage .tag-news {
        font-size: 14px;
        font-weight: 400;
        max-width: 160px;
    }

    #homepage .content-new {
        font-size: 14px;
        font-weight: 400;
    }

    #homepage .introduce-container, #homepage .question, #homepage .news, .knowledge-container {
        max-width: 750px;
    }

    #homepage .description-slide {
        font-size: 14px;
    }

    #homepage .description-slide {
        width: 400px;
    }
}

@media (max-width: 768px) {
    #homepage .introduce-container, #homepage .question, #homepage .news, .knowledge-container, #homepage .first-content {
        max-width: 600px;
        margin-inline: auto;
    }

    #homepage .accordion-button-custom, #main .accordion-body, #homepage .text-question {
        padding: 10px;
    }

    .introduce-container .mg {
        width: 100%;
    }

    #homepage .splide__pagination {
        justify-content: center;
        width: 100%;
        margin: 20px 0 0 0;
    }

    #homepage .partner {
        margin: 149px 20px 28px 20px;
    }

    #homepage .content-slide {
        display: block;
    }

    #homepage .info {
        margin: 8px 0px 16px;
        border: 3px solid #CCCCCC;
    }

    #homepage .image-slide {
        margin: unset;
        margin-right: 0px;
        display: flex;
        justify-content: center;
    }

    #homepage .content-slide .box-right {
        text-align: center;
        padding: 0px 80px;
    }

    #homepage #customer-splide::after {
        width: 100%;
        height: 90%;
        right: 0;
    }

    #homepage .description-slide {
        margin-right: 0px;
        text-align: justify;
        width: unset;
    }

    #homepage .question-number {
        margin-right: 50px;
    }

    .knowledge .knowledge-title {
        margin-bottom: 17px;
    }

    .knowledge .knowledge-img {
        border-radius: 8px;
        padding: 11px;
    }

    .knowledge .knowledge-img img {
        width: 35px;
        height: 35px;
    }

    #homepage .splide__pagination li,
    #homepage .splide__pagination li button {
        height: 8px;
        width: 8px;
        margin: 0 3px;
    }

    #homepage .third-layout {
        display: none;
    }

    #homepage .tech-layout-mb {
        display: block;
    }

    .tech-layout-mb .splide__slide {
        display: flex;
        justify-content: center;
    }

    #homepage .background-banner {
        height: 82%;
        max-height: 320px;
    }

    #homepage .box {
        padding: 20px 0px;
        display: -webkit-box;
    }

    #homepage .box div:first-child {
        margin-right: 20px;
        width: 60%;
    }

    #homepage .introduce img {
        margin-top: 20px;
        height: 80px;
        width: 220px;
    }

    #homepage .content-box {
        margin-bottom: unset;
    }

    #homepage .first-layout,
    #homepage .introduce-break-line,
    #homepage .row-border {
        border: none;
    }

    #homepage .row-border-custom .mg,
    #homepage .row-border .mg {
        border-top: 1px solid #D1D1D1;
    }

    .last-item-border {
        border-bottom: 1px solid #D1D1D1;
    }

    #homepage .tech-layout-mb .splide__pagination {
        margin: -30px 0px 0px 0px;
    }

    .tech-layout-mb .splide__pagination__page {
        background: #FFFFFF;
        margin: 0;
        opacity: 1;
    }

    .knowledge .content .item {
        width: 111px;
    }

    #homepage .title-layout {
        margin-top: 0;
    }

    #homepage .question .accordion-button::after {
        background-size: 17px;
    }
}

@media (max-width: 650px) {
    #homepage .news-slide {
        height: unset;
    }
    #homepage #news-splide {
        height: unset;
    }

    #homepage .content-box {
        margin-bottom: 12px;
        min-height: unset;
    }

    #homepage .partner {
        margin: 48px 20px 28px;
    }

    #homepage .background-banner:hover .content-tech {
        padding: 40px 24px 0px 24px;
    }

    .knowledge .content {
        gap: 10px 0px;
    }

    #homepage .knowledge-box {
        padding-top: 0;
    }

    #homepage .box {
        display: inline;
    }

    #homepage .box div:first-child {
        margin-right: 0px;
        width: 100%;
    }

    #homepage .introduce-container, #homepage .question, #homepage .news, .knowledge-container, #homepage .first-content {
        max-width: 480px;
    }

    #homepage .question-number {
        margin-right: 5px;
    }

    #homepage .introduce img {
        display: none;
    }

    .img-box {
        width: 327px;
        margin: auto;
        height: 73px;
        display: block;
        background-repeat: no-repeat;
    }

    .row-border-custom, .row-border {
        max-width: 350px;
        margin-inline: auto;
    }

    .img-box-1 {
        background-image: url("/frontend/assets/images/home/box1-2.png");
    }

    .img-box-2 {
        background-image: url("/frontend/assets/images/home/box2-2.png");
    }

    .img-box-3 {
        background-image: url("/frontend/assets/images/home/box3-2.png");
    }

    .img-box-4 {
        background-image: url("/frontend/assets/images/home/box4-2.png");
    }

    .img-box-5 {
        background-image: url("/frontend/assets/images/home/box5-2.png");
    }

    .img-box-6 {
        background-image: url("/frontend/assets/images/home/box6-2.png");
    }

    #homepage .box {
        padding: 40px;
    }
}

@media (max-width: 500px) {
    #homepage .banner-content {
        top: 100px;
        left: 10px;
    }

    #homepage .knowledge-box {
        padding: 0;
    }

    .knowledge .content .item {
        width: 75px;
    }

    #homepage .btn-custom {
        margin: 0px 37.5px;
    }

    #homepage .banner-content {
        font-size: 17px;
        font-weight: 600;
    }

    #homepage .btn-custom {
        font-size: 12px;
        font-weight: 600;
    }
    #homepage .introduce-container, #homepage .question, #homepage .news {
        max-width: 350px;
    }

    .knowledge-container {
        max-width: 375px;
    }

    #homepage .question .accordion-button::after, #homepage .question .accordion-button:not(.collapsed)::after {
        width: 17px;
        height: 26px;
        background-size: 17px;
        margin-top: 0;
        margin-right: 0;
    }
    
}

@media (max-width: 450px) {
    #homepage #customer-splide::after {
        top: 200px;
        height: 75%;
    }

    #homepage .image-slide img {
        width: 327px;
        height: 327px;
    }

    #homepage .banner-content {
        font-size: 24px;
        font-weight: 700;
        top: 218px;
        left: 0px;
        width: 100%;
    }

    #homepage .btn-custom {
        font-size: 14px;
        font-weight: 700;
    }

    #homepage .btn-custom {
        margin: 30px auto;
    }

    .slogan {
        height: 202px;
    }

    #homepage .content-slide .box-right {
        padding: 0px;
    }

    #homepage .first-content {
        padding: 0px 24px;
    }
}

@media (max-width: 410px) {
    #homepage .image-slide {
        justify-content: unset;
    }

    #homepage .third-layout, .tech-layout-mb {
        margin: 16px 25px 0px 25px;
    }

    .knowledge .knowledge-img {
        border-radius: 8px;
        padding: 8px;
    }

    .knowledge .knowledge-img img {
        width: 24px;
        height: 24px;
    }

    .slogan {
        height: 192px;
    }

    #homepage .partner {
        margin: 67px 20px 28px 20px;
    }

    #homepage .splide__pagination {
        margin-top: 10px;
    }

    #homepage #customer-splide::after {
        height: 65%;
        top: 295px;
    }

    #homepage .box {
        padding: 12px;
    }

    #homepage .news {
        padding-bottom: 36px;
    }

    #homepage .news-slide {
        padding: 24px 20px 32px 20px;
    }

    #homepage .date-new {
        margin-right: 16px;
    }

    #homepage .news-slide .convert-news {
        margin-top: 16px;
        margin-top: 15px;
    }
}